import React from 'react'
import MainBox from './MainBox'

interface Props {
  message: string
  title?: string
}

export default function ErrorMessage(props: Props) {
  return (
    <MainBox defaultPadding>
      {props.title && <p className="text-xxl bold my-4">{props.title}</p>}

      <p className="text-l my-1">{props.message}</p>
    </MainBox>
  )
}
