import React from 'react'
import { Badge, Button, Input } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'

import CONFIG from '../../config'
import { User_users_organization_distribution_emails } from '../../hasura/graphQlQueries/types/User'
import { UsersState, usersSelector } from '../../hasura/slices/users'
import { insertDistributionEmailAction } from '../../hasura/slices/distribution-emails'

// @ts-ignore
import addIcon from '../../lib/images/add-gray.svg'

interface Props {
  distributionEmails: User_users_organization_distribution_emails[]
  setDistributionEmails: (distributionEmails: User_users_organization_distribution_emails[]) => void
}

const validateEmail = (email: string) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)

export default function SelectReferringVet(props: Props) {
  const dispatch = useDispatch()

  const { accessToken, user }: UsersState = useSelector(usersSelector)

  const [name, setName] = React.useState<string | undefined>()
  const [email, setEmail] = React.useState<string | undefined>()
  const [isAddingVet, setIsAddingVet] = React.useState(false)

  const reset = () => {
    setIsAddingVet(false)
    setEmail(undefined)
    setName(undefined)
  }

  const referringVetOptions = user?.organization.distribution_emails || []
  const hasReferringVetOptions = referringVetOptions.length > 0

  return (
    <div className="mt-2 mb-3">
      <p className="text-s bold m-0">Referring Veterinarian(s)</p>

      <p className="text-s mb-1">
        Add{hasReferringVetOptions ? ' or select' : ''} who should be emailed the report on completion. Set up default email routing in
        your{' '}
        <span className="underline pointer" onClick={() => window.open(`${CONFIG.PLATFORM_URL}/settings`, '_blank')}>
          settings
        </span>
        .
      </p>

      <div>
        <table className="mt-1">
          {hasReferringVetOptions && (
            <thead className="text-xs">
              <tr>
                <th></th>
                <th className="regular">Name</th>
                <th className="regular">Email</th>
                <th className="regular">Role</th>
              </tr>
            </thead>
          )}

          <tbody className="text-m">
            {referringVetOptions.map((d, idx) => {
              const isAdmin = idx === 0
              const isChecked = props.distributionEmails.some((e) => e.id === d.id)
              return (
                <tr
                  className="pointer"
                  onClick={() =>
                    props.setDistributionEmails(
                      isChecked ? props.distributionEmails.filter((e) => e.id !== d.id) : props.distributionEmails.concat(d)
                    )
                  }
                  key={idx}
                >
                  <td className="pb-1">
                    <input checked={isChecked} className="data-hj-allow mt-1 mr-3" readOnly role="button" type="radio" />
                  </td>

                  <td className="width-250px pb-1">
                    <p className="mb-0">{d.display_name}</p>
                  </td>

                  <td className="width-250px pb-1">
                    <p className="mb-0">{d.email}</p>
                  </td>

                  <td className="width-250px pb-1">
                    <div className="d-flex align-items-center">
                      <Badge className="min-width-100px" color={isAdmin ? 'dark' : 'secondary'}>
                        {isAdmin ? 'Admin' : 'Veterinarian'}
                      </Badge>
                    </div>
                  </td>
                </tr>
              )
            })}

            {isAddingVet ? (
              <tr>
                <td></td>

                <td className="width-250px pr-3 pt-2">
                  <Input
                    onChange={(e) => setName(e.target.value)}
                    className="data-hj-allow"
                    autoFocus
                    type="text"
                    placeholder="Name"
                  />
                </td>

                <td className="width-250px pr-3 pt-2">
                  <Input onChange={(e) => setEmail(e.target.value)} className="mr-2 data-hj-allow" type="text" placeholder="Email" />
                </td>

                <td className="pt-2">
                  <div className="position-relative width-fit-content">
                    <Button
                      onClick={() => {
                        const distributionEmail = { email, organization_id: user!.organization.id, display_name: name }
                        dispatch(insertDistributionEmailAction(accessToken, distributionEmail, user!.id))
                        reset()
                      }}
                      disabled={!name || !email || !validateEmail(email)}
                      color="primary"
                      className="width-100px"
                    >
                      Add
                    </Button>

                    <p onClick={reset} className="text-xs position-absolute w-100 text-center pt-1 pointer">
                      Cancel
                    </p>
                  </div>
                </td>
              </tr>
            ) : (
              <tr>
                <td>
                  <img onClick={() => setIsAddingVet(true)} src={addIcon} className="icon-s pointer" />
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  )
}
