import React from 'react'
import { Table, Toast, ToastHeader } from 'reactstrap'

// @ts-ignore
import checkIcon from '../../lib/images/checkmark-green.svg'

export default function InternalMedicineChecklist() {
  return (
    <Toast className="border-primary">
      <ToastHeader className="bg--primary text--semibold text--white">Internal Medicine Checklist</ToastHeader>

      <Table size="sm" className="text-xs text--black m-0">
        <tbody>
          {[
            'Current diet & medications and any recent changes',
            'Patient environment',
            'Travel & vaccination history',
            'Presenting complaint',
            'Goal of consult and specific questions',
          ].map((item, index) => {
            const borderTop = index === 0 ? 'none' : ''
            return (
              <tr className="border-none" key={index}>
                <td style={{ borderTop }}>
                  <div className="flex-center">
                    <img className="icon-xxxs" src={checkIcon} />
                  </div>
                </td>
                <td style={{ borderTop }}>{item}</td>
                <td style={{ borderTop }}></td>
              </tr>
            )
          })}
        </tbody>
      </Table>
    </Toast>
  )
}
