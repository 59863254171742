import React, { useCallback } from 'react'
import last from 'lodash/last'
import { Button, Spinner } from 'reactstrap'
import { useDispatch } from 'react-redux'
import { useDropzone } from 'react-dropzone'

import callFlask from '../../hasura/callFlask'
import { NotificationId, setNotificationAction } from '../../hasura/slices/notifications'
import { pluralize } from '../../lib/helpers'

interface Props {
  caseId: number
  consultationAttachmentAwsS3Urls: string[]
  setConsultationAttachmentAwsS3Urls: (urls: string[]) => void
  setIsCreatingAttachments: (isCreatingAttachments: boolean) => void
  isCreatingAttachments: boolean
}

interface InsertConsultationAttachmentsResult {
  success: number
  aws_s3_urls?: []
}

export default function Attachments(props: Props) {
  const dispatch = useDispatch()

  const onDrop = useCallback(
    async (acceptedFiles: any[], _: any[]) => {
      const formData = new FormData()
      acceptedFiles.forEach((file: any) => formData.append('files[]', file))
      props.setIsCreatingAttachments(true)
      const result: InsertConsultationAttachmentsResult = await callFlask(
        `/consultation-attachments?case_id=${props.caseId}`,
        'POST',
        {},
        formData
      )
      props.setIsCreatingAttachments(false)
      if (result.success) {
        props.setConsultationAttachmentAwsS3Urls(props.consultationAttachmentAwsS3Urls.concat(...result.aws_s3_urls!))
      } else {
        dispatch(setNotificationAction(NotificationId.SomethingBadHappened))
      }
    },
    [props.consultationAttachmentAwsS3Urls]
  )

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxSize: 10000000, // 10MB
  })

  return (
    <div>
      <div className="text-s max-width-700px">
        <p className="bold m-0">Medical Records and Diagnostics</p>

        <p className="mb-2">
          Upload radiographs, patient history, physical examinations, treatments, blood tests, urinalysis, cytology, histopathology
          and/or any other relevant information.
        </p>
      </div>

      <div style={{ outline: 'none' }} {...getRootProps()} className="width-400px height-200px">
        <div className="border-upload h-100 w-100 rounded p-4 flex-center flex-column text-center">
          <input {...getInputProps()} />

          <div className="mt-1 mb-4 text-center">
            <h3 className="text-xl mt-3 mb-2 bold text-primary">Upload Attachments</h3>
          </div>

          {props.isCreatingAttachments ? (
            <Spinner color="primary" />
          ) : (
            <Button type="button" color="primary">
              Upload
            </Button>
          )}
        </div>
      </div>

      <div className="text-s">
        <p className="mt-1 mb-0 bold">{pluralize('attachment', props.consultationAttachmentAwsS3Urls.length)} uploaded</p>
        {props.consultationAttachmentAwsS3Urls.map((f, idx) => (
          <p key={idx} className="m-0">
            {last(f.split(`${props.caseId}-`))}
          </p>
        ))}
      </div>
    </div>
  )
}
