import extend from 'lodash/extend'
import moment from 'moment'
import omit from 'lodash/omit'
import orderBy from 'lodash/orderBy'

import { NEUTERED, OtherCase } from './config'
import { caseDescription } from './pastCases'
import { Case_cases_patient_cases } from '../../hasura/graphQlQueries/types/Case'

export const findOption = (options: any[], param: string | null) => options.find((o) => o.value === param?.toLowerCase())?.value || ''

export const formatOtherCaseNotes = (otherCases: OtherCase[], pastCases: Case_cases_patient_cases[]) =>
  otherCases.length
    ? `\n\n${orderBy(otherCases, [(o) => o[0], (o) => o[1]], ['asc', 'desc'])
        .map((o) => {
          const pastCase = pastCases.find((p) => p.id === o[1])
          if (!pastCase) return null

          return `* ${o[0] === 'compare' ? 'Compare to' : 'Combine with'}: ${caseDescription(pastCase)}`
        })
        .join('\n')}`
    : null

export interface PatientParams {
  age?: string
  breed: string
  display_name: string
  birthdate?: string
  gender: string
  id: number
  neutered: boolean
  species: string
  weight: string
}

export const getPatientParams = (id: number, formikValues: any) => {
  // @ts-ignore
  const patient: PatientParams = extend(
    {},
    omit(formikValues, ['notes', 'private_notes', 'neutered', 'imagesCount', 'pims_reference_number', 'regions']),
    { id, neutered: formikValues.neutered === NEUTERED }
  )

  if (patient.birthdate) {
    const formatted = moment(patient.birthdate)
    if (formatted.isValid()) {
      patient.birthdate = formatted.format('YYYY-MM-DD')
      delete patient['age']
    } else {
      patient.age = patient.birthdate
      delete patient['birthdate']
    }
  } else {
    delete patient['birthdate']
  }

  return patient
}
